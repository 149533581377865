import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, Suspense as _Suspense, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "w-full h-full flex flex-col justify-center items-center p-4 max-w-lg m-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PokerRoom = _resolveComponent("PokerRoom")!
  const _component_DividerWithText = _resolveComponent("DividerWithText")!
  const _component_LoadingAnimation = _resolveComponent("LoadingAnimation")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(), _createBlock(_Suspense, null, {
      default: _withCtx(() => [
        _createElementVNode("div", null, [
          _createVNode(_component_PokerRoom, { id: $props.id }, null, 8, ["id"]),
          (!$setup.hasSession)
            ? (_openBlock(), _createBlock(_component_DividerWithText, {
                key: 0,
                text: "Or"
              }))
            : _createCommentVNode("", true),
          (!$setup.hasSession)
            ? (_openBlock(), _createElementBlock("button", {
                key: 1,
                class: "btn-black w-full mt-4",
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($setup.handleLogin && $setup.handleLogin(...args)))
              }, " Login "))
            : _createCommentVNode("", true)
        ])
      ]),
      fallback: _withCtx(() => [
        _createVNode(_component_LoadingAnimation)
      ]),
      _: 1
    }))
  ]))
}