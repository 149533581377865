
import FooterLine from "@/components/FooterLine.vue"
import "@/assets/tailwind.css"
import useAuthStore from "./stores/useAuthStore"
import {
  NavigationGuardNext,
  RouteLocationNormalized,
  useRouter,
} from "vue-router"
import { storeToRefs } from "pinia"

export default {
  components: {
    FooterLine,
  },
  setup() {
    const router = useRouter()
    const authStore = useAuthStore()
    const { hasProfile } = storeToRefs(authStore)

    // auth check
    router.beforeEach(
      async (
        to: RouteLocationNormalized,
        _from: RouteLocationNormalized,
        next: NavigationGuardNext
      ) => {
        const session = await authStore.initSession()

        if (to.meta.requiresAuth) {
          if (!hasProfile.value && to.name !== "ProfileView")
            return next({ name: "ProfileView" })
          if (!session) return next({ name: "LoginView" })
        }

        next()
      }
    )
  },
}
