import { supabase } from "@/lib/supabase"
import { Room } from "@/types/global"
import { defineStore } from "pinia"
import { ref } from "vue"

export const useRoomsStore = defineStore("rooms", () => {
  const allRooms = ref<Room[]>([])

  /**
   *  Deletes a room via its id
   */
  async function deleteRoom(room: Room) {
    try {
      await supabase.from("rooms").delete().eq("id", room.id)
    } catch (error) {
      console.error("error", error)
    }
  }

  /**
   *  Add a new room to supabase
   */
  async function addRoom({
    user_id,
    title,
  }: {
    user_id: string
    title: string
  }): Promise<void> {
    try {
      const { data, error } = await supabase
        .from("rooms")
        .insert({ user_id, title })
        .single()

      if (error) {
        alert(error.message)
        console.error("There was an error inserting", error)
        return
      }

      if (!data) return

      allRooms.value.push(data)
    } catch (err) {
      alert("Error")
      console.error("Unknown problem inserting to db", err)
      return
    }
  }

  /**
   * Retrieve all rooms for the signed in user
   */
  async function fetchRooms() {
    try {
      const { data: rooms, error } = await supabase
        .from("rooms")
        .select("*")
        .order("id")

      if (error) {
        console.log("error", error)
        return
      }
      // handle for when no rooms are returned
      if (rooms === null) {
        allRooms.value = []
        return
      }
      // store response to allRooms
      allRooms.value = rooms
    } catch (err) {
      console.error("Error retrieving data from db", err)
    }
  }

  return { allRooms, fetchRooms, addRoom, deleteRoom }
})
