
import UserAuth from "@/components/UserAuth.vue"
import useAuthStore from "@/stores/useAuthStore"
import { storeToRefs } from "pinia"
import { defineComponent, watch } from "vue"
import { useRouter } from "vue-router"

export default defineComponent({
  name: "LoginView",
  components: { UserAuth },
  setup() {
    const router = useRouter()
    const authStore = useAuthStore()
    const { hasSession, hasProfile } = storeToRefs(authStore)

    watch([hasSession, hasProfile], () => {
      if (hasSession.value && hasProfile.value)
        router.push({ name: "RoomsView" })
    })

    return
  },
})
