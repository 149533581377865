
import { GiphyFetch } from "@giphy/js-fetch-api"
import { defineComponent, ref } from "vue"

const gf = new GiphyFetch("EFvZbPSp4qMPOwM7mWU7rnHHljfLULKZ")

export default defineComponent({
  name: "ProfileForm",
  props: {
    initialNickname: {
      type: String,
      required: true,
    },
    initialAvatarurl: {
      type: String,
      required: true,
    },
  },
  emits: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    submit: (nickName: string, avatarURL: string | null) => {
      return true
    },
  },
  setup(props) {
    const avatarurl = ref(props.initialAvatarurl)
    const nickname = ref(props.initialNickname)

    const randomAvatarURL = async () => {
      const { data: gif } = await gf.random({ tag: "dogs", type: "stickers" })
      avatarurl.value = gif.images.preview_gif.url
    }

    if (!avatarurl.value.length) randomAvatarURL()

    return {
      avatarurl,
      nickname,
      randomAvatarURL,
    }
  },
})
