
import { defineComponent } from "vue"

export default defineComponent({
  name: "PlayCard",
  emits: ["select"],
  props: {
    value: {
      type: Number,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Boolean,
      default: false,
    },
  },
})
