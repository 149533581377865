import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(["w-16 h-24 sm:w-12 sm:h-20 text-center self-center align-middle bg-emerald-100 rounded border", _ctx.selected ? 'animate-bounce' : '']),
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$.emit('select', _ctx.value))),
    disabled: _ctx.disabled
  }, _toDisplayString(_ctx.value), 11, _hoisted_1))
}