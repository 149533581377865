import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "w-full" }
const _hoisted_2 = { class: "mb-12 font-bold text-6xl" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "grid grid-cols-3 gap-2 my-2" }
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "mb-2 text-xl font-medium leading-tight" }
const _hoisted_7 = { class: "mt-8" }
const _hoisted_8 = { class: "grid grid-cols-4 sm:grid-cols-8 gap-4 md:gap-2" }
const _hoisted_9 = ["disabled"]
const _hoisted_10 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PlayCard = _resolveComponent("PlayCard")!
  const _component_ProfileForm = _resolveComponent("ProfileForm")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.room?.title), 1),
    (_ctx.hasProfileOrIsGuest)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.userList, (user, index) => {
              return (_openBlock(), _createElementBlock("div", {
                key: index,
                class: "text-center"
              }, [
                (user?.avatarURL)
                  ? (_openBlock(), _createElementBlock("img", {
                      key: 0,
                      class: _normalizeClass([[
              _ctx.userIsDone(user.nickName) ? 'dark:bg-green-600 bg-green-100' : '',
            ], "inline-flex items-center justify-center w-24 h-24 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600"]),
                      src: user?.avatarURL
                    }, null, 10, _hoisted_5))
                  : _createCommentVNode("", true),
                _createElementVNode("h5", _hoisted_6, _toDisplayString(user.nickName) + " " + _toDisplayString(_ctx.revealRound ? `(${_ctx.userEstimation(user.nickName)})` : ""), 1)
              ]))
            }), 128))
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              (_openBlock(), _createElementBlock(_Fragment, null, _renderList([0, 1, 2, 3, 5, 8, 13, 21], (card, index) => {
                return _createVNode(_component_PlayCard, {
                  key: index,
                  value: card,
                  onSelect: _ctx.handleEstimate,
                  disabled: _ctx.revealStarted || _ctx.revealRound,
                  selected: _ctx.userEstimation(_ctx.nickName) === card
                }, null, 8, ["value", "onSelect", "disabled", "selected"])
              }), 64))
            ])
          ]),
          _createElementVNode("div", null, [
            (_ctx.revealRound)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 0,
                  class: "btn-black w-full mt-12",
                  onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleNewGame && _ctx.handleNewGame(...args)))
                }, " New Game "))
              : (_openBlock(), _createElementBlock("button", {
                  key: 1,
                  class: "btn-black w-full mt-12",
                  onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleReveal && _ctx.handleReveal(...args))),
                  disabled: _ctx.revealStarted || _ctx.revealRound || !_ctx.allPlayerEstimated
                }, _toDisplayString(_ctx.revealStarted ? "3,2,1" : "Reveal"), 9, _hoisted_9))
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_10, [
          _createVNode(_component_ProfileForm, {
            onSubmit: _ctx.joinRoom,
            "initial-avatarurl": '',
            "initial-nickname": ''
          }, null, 8, ["onSubmit"])
        ]))
  ]))
}