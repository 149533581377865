import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Suspense as _Suspense, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full h-full flex flex-col justify-center items-center p-4 max-w-lg m-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RoomList = _resolveComponent("RoomList")!
  const _component_LoadingAnimation = _resolveComponent("LoadingAnimation")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(), _createBlock(_Suspense, null, {
      default: _withCtx(() => [
        _createElementVNode("div", null, [
          _createVNode(_component_RoomList),
          _createElementVNode("button", {
            class: "btn-black w-full mt-12",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($setup.handleLogout && $setup.handleLogout(...args)))
          }, " Logout ")
        ])
      ]),
      fallback: _withCtx(() => [
        _createVNode(_component_LoadingAnimation)
      ]),
      _: 1
    }))
  ]))
}