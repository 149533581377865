import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full cursor-pointer hover:bg-gray-200 focus:outline-none focus:bg-gray-200 transition duration-150 ease-in-out" }
const _hoisted_2 = { class: "flex items-center px-4 py-4 sm:px-6" }
const _hoisted_3 = { class: "min-w-0 flex-1 flex items-center" }
const _hoisted_4 = { class: "text-sm leading-5 font-medium truncate" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.room.title), 1)
      ]),
      _createElementVNode("div", null, [
        _createElementVNode("button", {
          class: "btn-black w-full",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.joinRoom && _ctx.joinRoom(...args)))
        }, "Join Room")
      ])
    ])
  ]))
}