
import { defineComponent, PropType } from "vue"
import { Room } from "../types/global"
import { useRouter } from "vue-router"

export default defineComponent({
  name: "RoomItem",
  props: {
    room: {
      type: Object as PropType<Room>,
      required: true,
    },
  },
  setup(props) {
    const router = useRouter()
    const joinRoom = () => {
      router.push({ name: "RoomView", params: { id: props.room.id } })
    }

    return { joinRoom }
  },
})
