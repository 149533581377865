
import RoomList from "@/components/RoomList.vue"
import LoadingAnimation from "@/components/LoadingAnimation.vue"
import useAuthStore from "@/stores/useAuthStore"
import { useRouter } from "vue-router"

export default {
  components: {
    RoomList,
    LoadingAnimation,
  },
  setup() {
    const authStore = useAuthStore()
    const router = useRouter()
    const handleLogout = async () => {
      if (await authStore.handleLogout()) {
        router.push({ name: "LoginView" })
      } else {
        console.log("something went wrong")
      }
    }
    return { handleLogout }
  },
}
