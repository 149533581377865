import { computed, ref } from "vue"
import { supabase } from "@/lib/supabase"
import { Session, Provider } from "@supabase/gotrue-js/dist/main/lib/types"
import { Credentials, Profile } from "../types/global"
import { defineStore } from "pinia"
import { AuthError } from "@supabase/supabase-js"

const useAuthStore = defineStore("auth", () => {
  const userSession = ref<Session | null>(null)
  const profile = ref<Profile | null>(null)

  const hasSession = computed(() => !!userSession.value)
  const hasProfile = computed(() => !!profile.value?.nickname)

  const initSession = async () => {
    const session = await supabase.auth.getSession()
    if (session.data.session) {
      const { data } = await supabase
        .from("profiles")
        .select("id, nickname, avatarurl")
        .single()

      console.log("profile", data)
      profile.value = data
    }

    return (userSession.value = session.data.session)
  }

  /**
   * Keeps track of if the user is logged in or out and will update userSession state accordingly.
   */
  supabase.auth.onAuthStateChange((event, session) => {
    userSession.value = session
  })

  /*
   * Handles user login via email + password into a supabase session.
   * If not password is empty, it will send a magic link to the users email address.
   */
  async function handleLogin(
    credentials: Credentials
  ): Promise<{ type: "otp" | "classic"; error?: AuthError }> {
    if (credentials?.password.length) {
      const { error } = await supabase.auth.signInWithPassword({
        email: credentials.email,
        password: credentials.password,
      })
      if (error) return { type: "classic", error }
    } else {
      const { error, data: user } = await supabase.auth.signInWithOtp({
        email: credentials.email,
      })
      if (error) return { type: "otp", error }
      if (!user) return { type: "otp" }
    }
    return { type: "classic" }
  }

  /*
   * Handles signup provided a valid credentials object.
   */
  async function handleSignup(credentials: Credentials) {
    try {
      const { email, password } = credentials
      // prompt user if they have not filled populated their credentials
      if (!email || !password) {
        alert("Please provide both your email and password.")
        return
      }
      const { error } = await supabase.auth.signUp({ email, password })
      if (error) {
        alert(error.message)
        console.error(error, error.message)
        return
      }
      alert("Signup successful, confirmation mail should be sent soon!")
    } catch (err) {
      alert("Fatal error signing up")
      console.error("signup error", err)
    }
  }

  /**
   * Handles signup via Third Party Login.
   * https://supabase.com/docs/guides/auth#third-party-logins
   */
  async function handleOAuthLogin(provider: Provider) {
    const { error } = await supabase.auth.signInWithOAuth({ provider })
    if (error) console.error("Error: ", error.message)
  }

  /**
   * Handles password reset. Will send an email to the given email address.
   */
  async function handlePasswordReset() {
    const email = prompt("Please enter your email:")
    if (!email) {
      window.alert("Email address is required.")
    } else {
      const { error } = await supabase.auth.resetPasswordForEmail(email)
      if (error) {
        alert("Error: " + error.message)
      } else {
        alert("Password recovery email has been sent.")
      }
    }
  }

  async function handleUpdateUser(credentials: Credentials) {
    try {
      const { error } = await supabase.auth.updateUser(credentials)
      if (error) {
        alert("Error updating user info: " + error.message)
      } else {
        alert("Successfully updated user info!")
        window.location.href = "/"
      }
    } catch (error) {
      alert(`Error updating user info: ${error}`)
    }
  }

  /**
   * Handles logging a user out of a supabase session
   */
  async function handleLogout(): Promise<boolean> {
    console.log("logging out")
    const { error } = await supabase.auth.signOut()

    if (error) return false
    return true
  }

  return {
    profile,
    userSession,
    handleLogin,
    handleOAuthLogin,
    handleSignup,
    handleLogout,
    handlePasswordReset,
    handleUpdateUser,
    initSession,
    hasSession,
    hasProfile,
  }
})

export default useAuthStore
