import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full h-full flex flex-col justify-center items-center p-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProfileForm = _resolveComponent("ProfileForm")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ProfileForm, {
      onSubmit: _ctx.handleSubmit,
      "initial-avatarurl": _ctx.profile?.avatarurl || '',
      "initial-nickname": _ctx.profile?.nickname || ''
    }, null, 8, ["onSubmit", "initial-avatarurl", "initial-nickname"])
  ]))
}