
import DividerWithText from "@/components/DividerWithText.vue"
import LoadingAnimation from "@/components/LoadingAnimation.vue"
import PokerRoom from "@/components/PokerRoom.vue"
import useAuthStore from "@/stores/useAuthStore"
import { storeToRefs } from "pinia"
import { useRouter } from "vue-router"

export default {
  components: {
    LoadingAnimation,
    PokerRoom,
    DividerWithText,
  },
  props: { id: { type: String, required: true } },
  setup() {
    const router = useRouter()
    const authStore = useAuthStore()
    const { hasSession } = storeToRefs(authStore)

    const handleLogin = () => {
      router.push({ name: "LoginView" })
    }

    return { handleLogin, hasSession }
  },
}
