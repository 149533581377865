import { supabase } from "@/lib/supabase"
import { Room } from "@/types/global"
import { defineStore } from "pinia"
import { ref } from "vue"

export const useRoomStore = defineStore("room", () => {
  const room = ref<Room>()

  const fetchRoom = async (id: string) => {
    const { data, error } = await supabase.functions.invoke("fetchRoom", {
      body: { id },
    })

    if (error) return { error }
    room.value = data
  }

  return { room, fetchRoom }
})
