import { createApp } from "vue"
import { createRouter, createWebHashHistory } from "vue-router"
import App from "./App.vue"
import "./assets/tailwind.css"
import { createPinia } from "pinia"
import RoomView from "./views/RoomView.vue"
import RoomsView from "./views/RoomsView.vue"
import LoginView from "./views/LoginView.vue"
import ProfileView from "./views/ProfileView.vue"

const pinia = createPinia()

const routes = [
  {
    path: "/",
    component: RoomsView,
    name: "RoomsView",
    meta: { requiresAuth: true },
  },
  {
    path: "/profile",
    component: ProfileView,
    name: "ProfileView",
    meta: { requiresAuth: true },
  },
  { path: "/login", component: LoginView, name: "LoginView" },
  {
    path: "/room/:id",
    name: "RoomView",
    component: RoomView,
    props: true,
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
})

const app = createApp(App)
app.use(pinia)
app.use(router)
app.mount("#app")
