
import { defineComponent, ref } from "vue"
import useAuthStore from "@/stores/useAuthStore"
import DividerWithText from "./DividerWithText.vue"
import { useRouter } from "vue-router"

export default defineComponent({
  name: "UserAuth",
  setup() {
    const router = useRouter()
    const email = ref("")
    const password = ref("")

    const { handleLogin, handleOAuthLogin, handleSignup, handlePasswordReset } =
      useAuthStore()

    const onLogin = async () => {
      const response = await handleLogin({
        email: email.value,
        password: password.value,
      })
      if (!response.error) {
        router.push({ name: "RoomsView" })
      }
    }

    return {
      email,
      password,
      onLogin,
      handleOAuthLogin,
      handleSignup,
      handlePasswordReset,
    }
  },
  components: { DividerWithText },
})
