
import { computed, defineComponent, onBeforeUnmount, onMounted, ref } from "vue"
import { useRoomStore } from "@/stores/useRoomStore"
import { storeToRefs } from "pinia"
import { supabase } from "@/lib/supabase"
import { useRouter } from "vue-router"
import useAuthStore from "@/stores/useAuthStore"
import ProfileForm from "./ProfileForm.vue"
import PlayCard from "./PlayCard.vue"

type Estimation = {
  card: number
  playedAt: Date
}

export default defineComponent({
  name: "PokerRoom",
  components: { ProfileForm, PlayCard },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const router = useRouter()
    const store = useRoomStore()
    const userAuthStore = useAuthStore()

    const nickName = ref<string | null>(null)
    const avatarURL = ref<string | null>(null)

    const hasJoined = ref(false)
    const revealRound = ref(false)
    const revealStarted = ref(false)

    const userList = ref<
      {
        presence_ref: string
        nickName: string
        onlineAt: Date
        avatarURL?: string
      }[]
    >([])

    const game = ref<Record<string, Estimation>>({})

    const userPlayed = ref(false)

    const { room } = storeToRefs(store)
    const { hasProfile, profile } = storeToRefs(userAuthStore)

    const hasProfileOrIsGuest = computed(() => {
      return hasProfile.value || !!nickName.value?.length
    })

    onMounted(() => {
      if (hasProfileOrIsGuest.value) {
        if (profile.value && profile.value.nickname) {
          nickName.value = profile.value.nickname
          avatarURL.value = profile.value.avatarurl
          joinRoom(nickName.value, avatarURL.value)
        }
      }
    })

    store.fetchRoom(props.id).then((result) => {
      if (result?.error) {
        alert("no room found")
        router.push({ name: "LoginView" })
      }
    })

    const gameChannel = supabase.channel(props.id, {
      config: {
        broadcast: {
          self: true,
        },
        presence: {
          key: `userId-${Math.random()}`,
        },
      },
    })

    onBeforeUnmount(() => gameChannel.unsubscribe())

    const joinRoom = (nick: string, url: string | null) => {
      nickName.value = nick
      avatarURL.value = url
      hasJoined.value = true

      gameChannel
        .on("presence", { event: "sync" }, () => {
          const state = gameChannel.presenceState<{
            nickName: string
            onlineAt: Date
            avatarURL?: string
          }>()
          console.log(Object.values(state))
          userList.value = Object.values(state).map((record) => {
            game.value = {}
            revealRound.value = false
            return {
              presence_ref: record[0].presence_ref,
              nickName: record[0].nickName,
              onlineAt: record[0].onlineAt,
              avatarURL: record[0].avatarURL,
            }
          })
        })
        .on("broadcast", { event: "reveal" }, () => {
          revealStarted.value = true
          setTimeout(() => {
            revealRound.value = true
            revealStarted.value = false
          }, 3000)
        })
        .on("broadcast", { event: "clear" }, () => {
          revealRound.value = false
          game.value = {}
        })
        .on("broadcast", { event: "estimate" }, (response) => {
          console.log(response)
          const { name, card, playedAt } = response.payload as {
            name: string
          } & Estimation

          if (game.value[name] && game.value[name].card === card)
            delete game.value[name]
          else game.value[name] = { card, playedAt }
        })
        .subscribe(async (status) => {
          if (status === "SUBSCRIBED") {
            gameChannel.track({
              nickName: nick,
              onlineAt: new Date().toISOString(),
              avatarURL: url,
            })
          }
        })
    }

    const userIsDone = (userName: string) => {
      return game.value[userName]
    }

    const userEstimation = (nickName: string | null) => {
      return nickName ? game.value[nickName]?.card : null
    }

    const allPlayerEstimated = computed(() => {
      if (!Object.keys(game.value).length) return false

      return Object.keys(game.value).length === userList.value.length
    })

    const handleReveal = () => {
      gameChannel.send({
        type: "broadcast",
        event: "reveal",
        payload: { revealStarted: true },
      })
    }
    const handleNewGame = async () => {
      return await gameChannel.send({
        type: "broadcast",
        event: "clear",
        payload: { revealRound: false },
      })
    }

    const handleEstimate = async (card: number) => {
      if (revealRound.value) return

      userPlayed.value = true
      const result = await gameChannel.send({
        type: "broadcast",
        event: "estimate",
        payload: {
          name: nickName.value,
          card,
          playedAt: new Date().toISOString(),
        },
      })
      if (result !== "ok") {
        userPlayed.value = false
      }
    }

    return {
      avatarURL,
      handleNewGame,
      allPlayerEstimated,
      handleReveal,
      revealStarted,
      revealRound,
      handleEstimate,
      joinRoom,
      hasJoined,
      room,
      nickName,
      userList,
      userPlayed,
      userIsDone,
      userEstimation,
      hasProfileOrIsGuest,
    }
  },
})
