import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "grid gap-2 grid-cols-1 my-2 place-content-center" }
const _hoisted_2 = ["src"]
const _hoisted_3 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("form", _hoisted_1, [
    (_ctx.avatarurl)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          src: _ctx.avatarurl,
          class: "w-40 h-40 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600"
        }, null, 8, _hoisted_2))
      : _createCommentVNode("", true),
    _createElementVNode("button", {
      type: "button",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.randomAvatarURL && _ctx.randomAvatarURL(...args))),
      class: "btn-black"
    }, " New Random Avatar "),
    _withDirectives(_createElementVNode("input", {
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.nickname) = $event)),
      class: "rounded w-full p-2",
      type: "text",
      placeholder: "Whats your name?"
    }, null, 512), [
      [_vModelText, _ctx.nickname]
    ]),
    _createElementVNode("button", {
      type: "submit",
      onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.$emit('submit', _ctx.nickname, _ctx.avatarurl)), ["prevent"])),
      class: "btn-black",
      disabled: _ctx.nickname.length < 3
    }, " Join ", 8, _hoisted_3)
  ]))
}