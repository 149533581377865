
import { defineComponent } from "vue"

export default defineComponent({
  name: "DividerWithText",
  props: {
    text: {
      type: String,
      required: true,
    },
  },
})
