
import { defineComponent, ref } from "vue"
import RoomItem from "@/components/RoomItem.vue"
import useAuthStore from "@/stores/useAuthStore"
import { useRoomsStore } from "@/stores/useRoomsStore"
import { storeToRefs } from "pinia"

export default defineComponent({
  name: "RoomList",
  components: {
    RoomItem,
  },
  setup() {
    const authStore = useAuthStore()
    const { userSession } = storeToRefs(authStore)
    const store = useRoomsStore()
    const { allRooms } = storeToRefs(store)

    store.fetchRooms()

    const roomTitle = ref("")

    /**
     * Wrapper function adding a new room for additional client-side error handling.
     */
    async function createRoom() {
      if (roomTitle.value.length <= 3) {
        alert("Please make your room a little more descriptive")
        return
      }

      if (userSession.value === null || !userSession.value.user)
        return alert("Please log in again")

      await store.addRoom({
        user_id: userSession.value.user.id,
        title: roomTitle.value,
      })
      await store.fetchRooms()

      // Reset input field.
      roomTitle.value = ""
    }

    return {
      roomTitle,
      allRooms,
      createRoom,
      userSession,
    }
  },
})
