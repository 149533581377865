
import ProfileForm from "@/components/ProfileForm.vue"
import { supabase } from "@/lib/supabase"
import useAuthStore from "@/stores/useAuthStore"
import { storeToRefs } from "pinia"
import { defineComponent } from "vue"
import { useRouter } from "vue-router"

export default defineComponent({
  name: "ProfileView",
  components: { ProfileForm },
  setup() {
    const authStore = useAuthStore()
    const { profile } = storeToRefs(authStore)
    const router = useRouter()

    const handleSubmit = async (nickName: string, avatarURL: string | null) => {
      const { error, data } = await supabase
        .from("profiles")
        .update({
          avatarurl: avatarURL,
          nickname: nickName,
        })
        .eq("id", profile.value?.id)
        .single()
      console.log(data, error)
      if (!error) router.push({ name: "RoomsView" })
    }

    return { handleSubmit, profile }
  },
})
